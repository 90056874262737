import React from "react"
import Helmet from "react-helmet"
import Banner from "../components/Banner"
import Footer from "../components/Footer"
import Header from "../components/Header"
import Container from "../components/Container"
import WordCloud from "../components/WordCloud"
import { Link } from 'gatsby';
import "../styles/scss/about.scss";


export default function AboutPage() {
  return (
    <>
      <Helmet
          htmlAttributes={{
            lang: 'en',
          }}>
        <meta charSet="utf-8" />
        <link
          href="https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,500;1,400;1,500&display=swap"
          rel="stylesheet"
        />
        <link rel="canonical" href="https://www.adrianbright.co.uk" />
        <title>Adrian Bright Front End Developer</title>
        <description>Adrian Bright Front End Developer About myself</description>
      </Helmet>
      <Banner />
      <Header />

      <Container>
        <div className="row">
          <div className="col-12">
            <h1>about</h1> 
            <p>I'm currently working as a Front End developer at Group GTI, where we are using the Agile discipline. I use day to day Angular with various other front end tools for their enterprise websites. To see my work history just view LinkedIn.</p>
            <p>After graduating in 1999 with a HND (Merit) in Graphic Design I spent nine years working in various graphic designer and web developer roles first in central London then in various locations around the south east. I acquired many varied design/coding skills as well as the ability to meet milestones and deadlines.</p>
            <p>I have worked on a multitude of web and print based projects for a range of clients providing Web/Email Design (Photoshop, Illustrator, InDesign) and development (HTML, CSS/SASS, Angular, jQuery, Wordpress, email templates, Opencart, Bootstrap). I have always had an interest in email marketing and ecommerce. During my years of design experience the UI experience and attention to detail I strive with.</p>
            <p>In my spare time I have a passion for my two classic Volkswagen vehicles and retro gaming.</p>
            <div className="hobbies">
              <img className="wider" src="./icons-08.svg" alt=""></img>
              <img src="./icons-01.svg" alt=""></img>
            </div>
            <p>Got any questions - <Link to={'/contact'}>contact me</Link></p>
            <h2>Some of the brands I have worked with in the past...</h2>
            <WordCloud />
          </div>
        </div>
      </Container>
      <Footer />
    </>
  )
}
