import React from "react"
import ReactWordcloud from "react-wordcloud";
import { Resizable } from "re-resizable";

const words = [
  {
    text: 'EasyGroup',
    value: 20,
  },
  {
    text: 'QVC',
    value: 65, 
  },
  {
    text: 'Philips Lighting',
    value: 40,
  },
  {
    text: 'T-Mobile',
    value: 62,
  },
  {
    text: 'O2',
    value: 45,
  },
  {
    text: 'Haven Holidays',
    value: 70,
  },
  {
    text: 'Optoma projectors',
    value: 43,
  },
  {
    text: 'Ernest & Julio Gallo',
    value: 64,
  },
  {
    text: 'Inchcape',
    value: 45,
  },
  {
    text: 'Setanta Sports',
    value: 40,
  },
  {
    text: 'Wilkinsons',
    value: 70,
  },
  {
    text: 'Barclaycard',
    value: 65,
  },
  {
    text: 'Benenden',
    value: 45,
  },
  
]

const resizeStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  marginBottom: "20px"
};

// const size = [600, 400];

const options = {
  fontFamily: 'Arial',
  rotations: 2,
  rotationAngles: [-90, 0]
};

const callbacks = { 
  // getWordColor: word => word.value > 50 ? "#575e8e" : "#000",
  // onWordClick: console.log,
  // onWordMouseOver: console.log,
  // getWordTooltip: word => `${word.text} (${word.value}) [${word.value > 50 ? "good" : "bad"}]`,
}

export default function WordCloud() {
  return (

    <Resizable
    defaultSize={{
      width: 500,
      height: 300
    }}
    style={resizeStyle} >
    <div style={{ width: "100%", height: "100%" }}>
    <ReactWordcloud 
    callbacks={callbacks}
    options={options}
    // size={size}
    words={words} />
    </div>
  </Resizable>

  )
}